import Blocks from 'components/blocks/blocks';
import SEO from 'components/seo/seo';
import { graphql, Script } from 'gatsby';
import React, { useEffect, useRef, useState } from 'react';
import { pageContextProps } from 'utils/props.utils';
import imagesLoaded from 'imagesloaded';
import { handlePageLoaded } from 'components/trans/transLink';
import parse from 'html-react-parser';
import ReactDOM from 'react-dom';

import classNames from 'classnames';

import './donation.template.scss';

interface Props {
  pageContext: pageContextProps;
  data: {
    page: {
      blocks: any[];
    };
  };
}

const DonationTemplate: React.FC<Props> = (props) => {
  const { title, id, seo } = props.pageContext;
  const isBrowser = typeof window !== 'undefined';

  const Wysiwyg = ({ children }) => {
    const reactElements = parse(children || '', {
      replace: handleShortcodes,
    });

    return <div>{reactElements}</div>;
  };

  const handleShortcodes = (node) => {
    if (node.type && node.type === 'text') {
      const shortcode = node.data;
      if (shortcode.includes('[donation_form')) {
        // return <DonationPanel />;
      }
    }

    // If nothing then return original node
    return node;
  };

  const mainRef = useRef<HTMLDivElement>(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  useEffect(() => {
    const main = mainRef.current;
    !!main && imagesLoaded(document.querySelectorAll('[data-preload]'), handlePageLoaded);
    setIsLoaded(true);
  }, []);

  return (
    <div className={classNames('page-template', { 'is-loading-complete': isLoaded })} id={id} ref={mainRef}>
      <SEO {...seo} />
      <section className="section">
        <div className="section-header">
          <h5 className="section-header__title">{title}</h5>
        </div>
        <div className="section-content page-content">
          <div className={classNames('donation-detail', { 'is-loaded': isLoaded })}>
            <Blocks blocks={props.data.page.blocks} />
            <div class="footer-buttons">
              <a href="https://donate.raisenow.io/fyggf?analytics.channel=button&lng=de" target="_blank" >
                <span>Jetzt spenden</span>
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default DonationTemplate;

export const query = graphql`
  query ($id: String!, $language: String!) {
    page: wpPage(id: { eq: $id }) {
      blocks {
        name
        order
        ...AnchorLinksBlock
        ...AuthorLineBlock
        ...ContactBlock
        ...DataWrapperBlock
        ...FormBlock
        ...ForschungDetailBlock
        ...GalleryBlock
        ...GraphBlock
        ...HeadingBlock
        ...HeroBlock
        ...HeroSmallBlock
        ...ImageBlock
        ...InfoBoxBlock
        ...LinksBlock
        ...ListBlock
        ...MultiColumnTextBlock
        ...PageFooterTextBlock
        ...ParagraphBlock
        ...PartnerBlock
        ...PostWithTagBlock
        ...PullQuoteBlock
        ...ReadMoreBlock
        ...SidTeasersBlock
        ...SourceBlock
        ...TeamGridBlock
        ...TeamGridSimpleBlock
        ...TopicsBlock
        ...ValuesBlock
        ...VideoIframeBlock
        ...ShareButtonsBlock
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
